<template>
  <div>
    <Heard />
    <!-- <Tip /> -->
    <div class="container person-box">
      <Aside />
      <div class="right-box">
        <el-table
          :header-cell-style="{
            background: '#f8f8f8',
            height: '40px',
          }"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="投递记录" width="800">
            <template slot-scope="scope">
              <div
                class="shopbox"
                @click="$router.push(`/postdeil/${scope.row.jobid}.html`)"
              >
                <img
                  :src="
                    scope.row.companyInfoDto.logoImgPath
                      ? scope.row.companyInfoDto.logoImgPath
                      : require('@/assets/image/baseicon.png')
                  "
                  alt=""
                />
                <div class="namebox">
                  <p class="p1">{{ scope.row.jobName }}</p>
                  <p class="p2">{{ scope.row.companyName }}</p>
                  <p class="p3">
                    <span>{{ scope.row.address }}|{{ scope.row.edu }}</span>
                  </p>
                </div>
                <div class="price">{{ scope.row.umoney }}</div>
              </div>
              <div class="time">{{ scope.row.createTime }}</div>
            </template>
          </el-table-column>

          <!-- <el-table-column label="分值">
            <template slot-scope="scope">
              <div class="numcolor">{{ scope.row.score }}</div>
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Heard from "@/components/Heard.vue";
// import Tip from "@/components/Tip.vue";
import Aside from "@/components/Aside.vue";
import Footer from "@/components/Footer.vue";
import { api_records } from "@/apis/apis"; //简历诊断记录
import { getUser } from "@/plugins/auth.js";

export default {
  components: {
    Heard,
    // Tip,
    Aside,
    Footer,
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      user: {},
      currPage: 1,
      totalCount: 1,
    };
  },
  created() {
    this.user = getUser();
    this.getlist();
  },
  methods: {
    getlist() {
      api_records({
        userId: this.user.id,
        currPage: this.currPage,
      }).then((res) => {
        this.tableData = res.data.data.dataList;
        this.totalCount = res.data.data.totalCount;
      });
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(e) {
      console.log(e);
      this.currPage = e;
      this.getlist();
    },
  },
};
</script>

<style lang="less" scoped>
.person-box {
  display: flex;
  .right-box {
    min-width: 960px;
    border: 1px solid #f2f2f2;
    padding: 20px;
    .shopbox {
      display: flex;
      margin-bottom: 20px;

      img {
        width: 80px;
        height: 80px;
        margin-right: 60px;
      }
      .namebox {
        width: 400px;
        .p1 {
          font-size: 18px;
          font-weight: bold;
          color: #000;
        }
        .p2 {
          margin: 10px 0;
        }
      }
      .price {
        margin-left: 60px;
        color: #ff8800;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .time {
      margin-left: 40px;
    }
    .numcolor {
      font-size: 26px;
      font-weight: bold;
      color: #ff8800;
    }
  }
}
</style>